module.exports = [{
      plugin: require('../node_modules/@openstack_dev/gatsby-theme-marketing-oif-core/plugins/gatsby-plugin-mui-theme-provider/gatsby-browser.js'),
      options: {"plugins":[],"themePath":"/opt/build/repo/node_modules/@openstack_dev/gatsby-theme-marketing-oif-core/src/theme.js"},
    },{
      plugin: require('../node_modules/@openstack_dev/gatsby-theme-marketing-oif-core/plugins/gatsby-plugin-emotion-cache-provider/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Open Source Cloud Computing Infrastructure - OpenStack","short_name":"Open Source Cloud Computing Infrastructure - OpenStack","description":"OpenStack is an open source cloud computing infrastructure software project and is one of the three most active open source projects in the world.","start_url":"/","display":"minimal-ui","icon":"src/content/site-settings/favicon-32x32.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"cbb36e97af1e4c996ca3f284ccc082d6"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/node_modules/@openstack_dev/gatsby-theme-marketing-oif-core/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T59Q8GH","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"nxuu6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-quantcast-tag/gatsby-browser.js'),
      options: {"plugins":[],"acccountId":"p-fM0cfazHHeyRH","head":false},
    },{
      plugin: require('../node_modules/@openstack_dev/gatsby-theme-marketing-oif-core/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
