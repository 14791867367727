import { createTheme } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";

// create breakpoints in intermediate theme
const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      main: "#2A4E68",
      light: "#286a9d",
      dark: "#1565c0",
      contrastText: "#FFFFFF",
    },
    background: {
      paper: "#FFFFFF",
      light: "#F9FAFC",
      contrastText: "#000000DE",
    },
    text: {
      primary: "#333333",
      secondary: "#2A4E68",
    },
  },
});

const theme = createTheme(baseTheme, {
  typography: {
    h1: {
      fontSize: 34,
      color: baseTheme.palette.text.secondary,
      fontWeight: 300,
      textAlign: "center",
      margin: "40px 0",
    },
    h2: {
      fontSize: 30,
      color: baseTheme.palette.text.secondary,
      fontWeight: 300,
      marginTop: 20,
      marginBottom: 25,
    },
    h3: {
      fontSize: 26,
      color: baseTheme.palette.text.secondary,
      fontWeight: 300,
    },
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "\"Open Sans\",Helvetica,Arial,sans-serif",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: "100%",
          verticalAlign: "baseline",
          fontFamily: "\"Open Sans\", Helvetica, Arial, sans-serif",
          "& a": {
            color: "#337ab7",
            textDecoration: "none",
            "&:hover": {
              color: "#30739C",
              textDecoration: "underline",
            },
          },
          hr: {
            marginTop: 20,
            marginBottom: 20,
            border: 0,
            borderTop: "1px solid #eee",
          },
        },
      },
    },
  },
});

export default theme;
